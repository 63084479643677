import { ref } from 'vue';
import PouchDB from 'pouchdb-browser';
import type { IWinner } from '@/interface/IWinner';

export const Winners = ref<PouchDB.Core.ExistingDocument<IWinner>[]>([]);

export class WinnerService {
  private db: PouchDB.Database<IWinner> = new PouchDB('winner');

  async init() {
    Winners.value = await this.getWinners();
  }

  public async addWinner(name: string, prize: string): Promise<PouchDB.Core.Response> {
    const winner: IWinner = {
      name,
      prize,
      date: new Date().toLocaleDateString('en-AU') + ' ' + new Date().toLocaleTimeString('en-Au')
    };
    const result = await this.db.post(winner);
    await this.syncWinners();
    return result;
  }

  public async getWinners(): Promise<PouchDB.Core.ExistingDocument<IWinner>[]> {
    const result = await this.db.allDocs<IWinner>({ include_docs: true });
    return result.rows.map((row) => row.doc!);
  }

  private async syncWinners() {
    Winners.value = await this.getWinners();
  }

  public async removeWinner(winner: PouchDB.Core.ExistingDocument<IWinner>) {
    const _winner: PouchDB.Core.ExistingDocument<IWinner & PouchDB.Core.ChangesMeta> = winner;
    _winner._deleted = true;
    const result = await this.db.put(_winner);
    await this.syncWinners();
    return result;
  }

  public async clearWinners() {
    const allDocs = await this.db.allDocs<IWinner>({ include_docs: true });
    const deleteDocs = allDocs.rows.map((doc) => ({
      ...doc.doc,
      _deleted: true
    })) as PouchDB.Core.PutDocument<IWinner & PouchDB.Core.ChangesMeta>[];
    await this.db.bulkDocs(deleteDocs);
    await this.syncWinners();
  }
}
