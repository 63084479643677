export const templateItems = [
  {
    group: 'Main',
    label: 'Virgin Voucher x25',
    weight: 1,
    order: 1
  },
  {
    group: 'Main',
    label: 'Hot Sauce Chip',
    weight: 4,
    order: 2
  },
  {
    group: 'Main',
    label: 'RM Williams Boots x10',
    weight: 1,
    order: 3
  },
  {
    group: 'Main',
    label: 'Hot Sauce Chip or Lolly Jar',
    weight: 3,
    order: 4
  },
  {
    group: 'Main',
    label: 'Virgin Voucher x10',
    weight: 1,
    order: 5
  },
  {
    group: 'Main',
    label: 'Hot Sauce Chip',
    weight: 4,
    order: 6
  },
  {
    group: 'Main',
    label: 'RM Williams Boots x25',
    weight: 1,
    order: 7
  },
  {
    group: 'Main',
    label: 'Hot Sauce Chip',
    weight: 4,
    order: 8
  },
  {
    group: 'Main',
    label: 'Go Pro Hero 12 x25',
    weight: 1,
    order: 9
  },
  {
    group: 'Main',
    label: 'Hot Sauce Chip or Lolly Jar',
    weight: 3,
    order: 10
  },
  {
    group: 'Main',
    label: 'Go Pro Hero 12 x10',
    weight: 1,
    order: 11
  }
];
