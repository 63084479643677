<template>
  <Dropdown
    id="group-dropdown"
    :model-value="GroupLabel"
    :options="GroupLabels"
    class="hidden"
    @update:model-value="itemService?.changeGroupLabel"
    :pt="{
      input: {
        class: 'text-xl sm:text-4xl md:text-6xl'
      },
      item: {
        class: 'text-xl sm:text-xl md:text-3xl'
      }
    }"
  />

  <div ref="container" class="flex spin-container">
    <picture>
      <img src="/img/background.webp" class="image" alt="background image" />
    </picture>
    <div
      class="icon"
      @click="spin()"
      @keyup.enter="spin()"
      @keyup.space="spin()"
      v-tooltip.bottom="{
        value: `↻ Spin!`,
        class: 'text-xl',
        escape: true
      }"
      tabindex="0"
    ></div>
    <div class="wheel" ref="wheelContainer"></div>
  </div>
</template>

<script setup lang="ts">
import { ref, onMounted, inject, watch } from 'vue';
import random from 'random';
import { Wheel } from 'spin-wheel/dist/spin-wheel-esm';
import { useDialog } from 'primevue/usedialog';
import { TickSound, LabelLength } from '@/services/SettingService';
import { GroupLabel, GroupLabels, ItemService, Items } from '@/services/ItemService';
import { VisibleSidebar } from '@/services/SidebarService';
import CongratulationDialog from '@/components/CongratulationDialog.vue';

const itemService = inject<ItemService>('ItemService');

const properties = {
  isInteractive: false,
  radius: 0.78,
  rotationResistance: 0.5,
  itemLabelRadius: 0.92,
  itemLabelRadiusMax: 0.3,
  itemLabelRotation: 180,
  itemLabelAlign: 'left',
  itemLabelColors: ['#fff'],
  itemLabelBaselineOffset: -0.07,
  itemLabelFont: '"Montserrat", "Mochiy Pop P One", "Jua", "Unbounded", "Mitr", "Noto Sans TC", "Noto Sans SC", "Noto Sans Lao", "Noto Color Emoji"',
  itemLabelFontSizeMax: 45,
  itemBackgroundColors: ['#34257e', '#e6d035', '#f83428', '#9f3576', '#219ae2', '#30b275'],
  rotationSpeedMax: 6000,
  lineWidth: 1,
  lineColor: '#fff',
  items: []
};

const container = ref();
const wheelContainer = ref();
let wheel: Wheel | undefined = undefined;

const isIdleSpinning = ref(false);

const stopAndClearSound = () => {
  if (!wheel) return;

  wheel.onCurrentIndexChange = undefined;
  wheel.stop();
};

const playSound = () => {
  if (!TickSound.value) return;

  const src = TickSound.value.value.startsWith('data:')
    ? TickSound.value.value
    : `/sound/${TickSound.value.value}`;
  const audio = new Audio(src);
  audio.volume = 0.3;
  audio.play();
};

const spin = () => {
  if (!wheel) return;

  isIdleSpinning.value = false;
  wheel.stop(); // Ensure idle spin stops before starting a new spin

  wheel.onCurrentIndexChange = () => {
    if (!wheel) return;

    playSound();

    switch (true) {
      case wheel.rotationSpeed < 200:
        wheel.rotationResistance = -100;
        break;
      case wheel.rotationSpeed < 50:
        wheel.rotationResistance = -30;
        break;
      case wheel.rotationSpeed < 30:
        wheel.rotationResistance = -10;
        break;
    }
  };

  wheel.rotationResistance = -400;
  wheel.spin(wheel.rotationSpeed + random.int(800, 1200));
};

const dialog = useDialog();
const openCongratulationDialog = ($event: { type: 'rest'; currentIndex: number; rotation: number }) => {
   dialog.open(CongratulationDialog, {
    props: {
      modal: true,
      showHeader: false,
      contentStyle: 'border: 0; backgroundColor: #a1d8276d; width: 100vw; height: 100vh; padding-top: 10%;',
      dismissableMask: true
    },
    data: {
      item: Items.value![$event.currentIndex],
      startIdleSpin
    }
  });
}

const startIdleSpin = () => {
  if (!wheel) return;

  isIdleSpinning.value = true;
  const idleSpeed = 20; // Slow idle speed
  wheel.rotationResistance = 0; // Minimal resistance for smooth spinning
  wheel.spin(idleSpeed);
};

onMounted(() => {
  watch(Items, (newValue) => (wheel!.items = newValue || []));
  watch(LabelLength, (newValue) => {
    wheel!.itemLabelRadiusMax = 1 - newValue;
  });

  wheel = new Wheel(wheelContainer.value, {
    ...properties,
    items: Items.value,
    itemLabelRadiusMax: 1 - LabelLength.value
  });

  wheel.onRest = ($event) => {
    stopAndClearSound();
    if (!isIdleSpinning.value) {
      openCongratulationDialog($event);
    } else {
      startIdleSpin(); // Resume idle spinning
    }
  };

  setTimeout(() => {
    wheel!.itemLabelRadiusMax = 1 - LabelLength.value;
  }, 50);

  startIdleSpin();
});

watch(VisibleSidebar, (newValue) => {
  if (!newValue) { // Sidebar closed
    startIdleSpin();
  }
});

</script>


<style lang="scss" scoped>
@import 'primeflex/core/_variables.scss';

.spin-container {
  aspect-ratio: 1/1;
  width: 100%;
  height: 90vh;

  margin-top: -3.5rem;
  margin-bottom: -10vh;
  position: relative;

  margin-left: 0;
  left: 0;

  @media (min-width: map-get($breakpoints, 'sm')) {
    height: 100vh;
  }

  @media (min-width: map-get($breakpoints, 'md')) {
    height: 110vh;
  }
}

.image {
  object-position: center;
  object-fit: contain;

  aspect-ratio: 1/1;
  width: 100%;
  height: 90vh;

  position: absolute;
  top: calc(50% - 45vh);
  left: 0;

  @media (min-width: map-get($breakpoints, 'sm')) {
    height: 100vh;
    top: calc(50% - 50vh);
  }

  @media (min-width: map-get($breakpoints, 'md')) {
    height: 110vh;
    top: calc(50% - 55vh);
  }
  z-index: 2;
}

.icon {
  $icon-size: 13vh;
  cursor: pointer;

  width: $icon-size;
  height: $icon-size;
  border-radius: 50%;

  background-image: url(/img/spinner-icon.webp);
  background-image: -webkit-image-set(
    url(/img/spinner-icon.webp) type('image/png')
  );
  background-image: image-set(
    url(/img/spinner-icon.webp) type('image/png')
  );

  background-size: contain;

  position: absolute;
  top: calc(56% - 6.5vh);
  left: calc(30% - 6.5vh);

  &:hover {
    filter: brightness(1.1);
  }
  z-index: 10;
}

.wheel {
  position: absolute;
  top: 55%; /* Vertically centers the wheel within the spin-container */
  left: 30%; /* Adjust this value to correctly position the wheel */
  transform: translate(-50%, -50%); /* Centers the wheel at the specified position */
  width: 90%; /* Increase this value to make the wheel bigger */
  height: auto; /* Maintains aspect ratio */
}
</style>
