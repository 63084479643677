<template>
  <Toast position="bottom-left" />
  <ConfirmPopup id="confirm" aria-label="popup">
    <template #message="slotProps">
      <div class="flex flex-column align-items-center w-full gap-3 p-3">
        <i class="text-6xl" :class="slotProps.message.icon"></i>
        <span>{{ slotProps.message.message }}</span>
      </div>
    </template>
  </ConfirmPopup>
  <ScrollPanel class="h-screen">
    <div class="flex-container">
      <div class="spinwheel-container">
        <SpinWheel />
      </div>
    </div>
  </ScrollPanel>

  <SidebarPanel />
  <Button
    severity="info"
    text
    rounded
    icon="pi pi-angle-double-left"
    aria-label="Open sidebar"
    class="overflow-visible sidebar-button"
    @click="sidebarService?.openSidebar"
    :pt="{
      icon: { style: { fontSize: 'xx-large' } }
    }"
  />
  <DynamicDialog />
</template>

<script setup lang="ts">
import { inject } from 'vue';
import type { SidebarService } from '@/services/SidebarService';

declare global {
  interface Navigator {
    globalPrivacyControl?: boolean;
  }
}

const sidebarService = inject<SidebarService>('SidebarService');
</script>

<style lang="scss" scoped>
.header {
  font-family: 'Rock Salt';
  -webkit-text-shadow: 9px 7px 20px #000000;
  text-shadow: 9px 7px 20px #000000;

  h1 {
    font-size: xxx-large;
  }
  p {
    font-size: large;
  }
}

.confirm-button {
  float: right;
}

.flex-container {
  display: flex;
  justify-content: center;
  align-items: flex-start; /* Align items to the start to have the same top line */
  gap: 20px; /* Space between the spinwheel and prize table */
  width: 100%; /* Ensure the container takes the full width */
}

.spinwheel-container {
  flex: 1;
  display: flex;
  justify-content: center;
  align-items: center;
}

@mixin afterBg {
  content: '';
  position: absolute;
  top: 0;
  left: 0;
  bottom: 0;
  right: 0;
  border-radius: 50%;
}

.sidebar-button {
  position: fixed;
  top: calc(50% - 25px);
  right: 1rem;
  transform: translateY(-50%);
  width: 50px;
  height: 50px;
  z-index: 999;

  animation: shockwaveJump 2s ease-out infinite;

  &:after {
    @include afterBg;
    animation: shockwave 2s 0.65s ease-out infinite;
  }

  &:before {
    @include afterBg;
    animation: shockwave 2s 0.5s ease-out infinite;
  }
}

@keyframes shockwaveJump {
  0% {
    transform: scale(1);
  }
  40% {
    transform: scale(1.08);
  }
  50% {
    transform: scale(0.98);
  }
  55% {
    transform: scale(1.02);
  }
  60% {
    transform: scale(0.98);
  }
  100% {
    transform: scale(1);
  }
}

@keyframes shockwave {
  0% {
    transform: scale(1);
    box-shadow:
      0 0 2px rgba(255, 255, 255, 0.15),
      inset 0 0 1px rgba(255, 255, 255, 0.15);
  }
  95% {
    box-shadow:
      0 0 50px rgba(255, 255, 255, 0),
      inset 0 0 30px rgba(255, 255, 255, 0);
  }
  100% {
    transform: scale(2.25);
  }
}
</style>